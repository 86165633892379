// Disable prettier for this snippet
export default function Logo({ className }: { className?: string }) {
  return (
    // prettier-ignore
    <svg width="1000" height="217" viewBox="0 0 1000 217" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M155.58 160.9C158.518 160.9 160.9 158.518 160.9 155.58C160.9 152.642 158.518 150.26 155.58 150.26C152.642 150.26 150.26 152.642 150.26 155.58C150.26 158.518 152.642 160.9 155.58 160.9Z" fill="current"/>
      <path d="M108.26 160.9C111.198 160.9 113.58 158.518 113.58 155.58C113.58 152.642 111.198 150.26 108.26 150.26C105.322 150.26 102.94 152.642 102.94 155.58C102.94 158.518 105.322 160.9 108.26 160.9Z" fill="current"/>
      <path d="M60.9401 160.9C63.8783 160.9 66.2601 158.518 66.2601 155.58C66.2601 152.642 63.8783 150.26 60.9401 150.26C58.002 150.26 55.6201 152.642 55.6201 155.58C55.6201 158.518 58.002 160.9 60.9401 160.9Z" fill="current"/>
      <path d="M155.58 113.58C158.518 113.58 160.9 111.198 160.9 108.26C160.9 105.322 158.518 102.94 155.58 102.94C152.642 102.94 150.26 105.322 150.26 108.26C150.26 111.198 152.642 113.58 155.58 113.58Z" fill="current"/>
      <path d="M60.9401 113.58C63.8783 113.58 66.2601 111.198 66.2601 108.26C66.2601 105.322 63.8783 102.94 60.9401 102.94C58.002 102.94 55.6201 105.322 55.6201 108.26C55.6201 111.198 58.002 113.58 60.9401 113.58Z" fill="current"/>
      <path d="M155.58 66.2601C158.518 66.2601 160.9 63.8783 160.9 60.9401C160.9 58.002 158.518 55.6201 155.58 55.6201C152.642 55.6201 150.26 58.002 150.26 60.9401C150.26 63.8783 152.642 66.2601 155.58 66.2601Z" fill="current"/>
      <path d="M108.26 66.2601C111.198 66.2601 113.58 63.8783 113.58 60.9401C113.58 58.002 111.198 55.6201 108.26 55.6201C105.322 55.6201 102.94 58.002 102.94 60.9401C102.94 63.8783 105.322 66.2601 108.26 66.2601Z" fill="current"/>
      <path d="M60.9401 66.2601C63.8783 66.2601 66.2601 63.8783 66.2601 60.9401C66.2601 58.002 63.8783 55.6201 60.9401 55.6201C58.002 55.6201 55.6201 58.002 55.6201 60.9401C55.6201 63.8783 58.002 66.2601 60.9401 66.2601Z" fill="current"/>
      <path d="M133.88 100.29H111.45V81.4599C111.45 81.0057 111.309 80.5628 111.045 80.193C110.781 79.8233 110.408 79.5452 109.979 79.3976C109.549 79.25 109.084 79.2403 108.649 79.3698C108.213 79.4993 107.829 79.7615 107.55 80.1199L80.9102 112.71C80.6587 113.033 80.5031 113.42 80.4612 113.827C80.4193 114.234 80.4928 114.644 80.6732 115.011C80.8537 115.379 81.1339 115.688 81.4817 115.903C81.8296 116.119 82.231 116.232 82.6402 116.23H105.07V135.06C105.07 135.514 105.212 135.957 105.476 136.327C105.74 136.697 106.112 136.975 106.542 137.122C106.971 137.27 107.436 137.28 107.872 137.15C108.307 137.021 108.691 136.758 108.97 136.4L135.61 103.81C135.862 103.487 136.017 103.1 136.059 102.693C136.101 102.286 136.028 101.876 135.847 101.508C135.667 101.141 135.387 100.832 135.039 100.617C134.691 100.401 134.289 100.288 133.88 100.29V100.29Z" fill="current"/>
      <path d="M246.68 128.21V137.33H211.43V79.27H246.27V88.4H221V103.49H244.2V112.49H221V128.17L246.68 128.21Z" fill="current"/>
      <path d="M290.54 128.21V137.33H257.36V79.27H266.9V128.21H290.54Z" fill="current"/>
      <path d="M333.2 128.21V137.33H297.94V79.27H332.78V88.4H307.48V103.49H330.71V112.49H307.48V128.17L333.2 128.21Z" fill="current"/>
      <path d="M339.48 108.3C339.48 91.47 352.17 78.19 369.84 78.19C374.856 78.1064 379.806 79.3428 384.194 81.7754C388.582 84.208 392.253 87.7513 394.84 92.05L386.54 96.86C383.47 91.13 377.17 87.48 369.87 87.48C357.35 87.48 349.05 96.36 349.05 108.3C349.05 120.24 357.35 129.12 369.87 129.12C377.17 129.12 383.56 125.47 386.54 119.75L394.84 124.48C390.03 132.94 380.57 138.41 369.84 138.41C352.17 138.41 339.48 125.14 339.48 108.3Z" fill="current"/>
      <path d="M442.2 88.4H425.86V137.33H416.32V88.4H399.9V79.27H442.2V88.4Z" fill="current"/>
      <path d="M470.43 116.18H459.15V137.33H449.61V79.2699H472.83C475.284 79.2607 477.716 79.7373 479.986 80.6722C482.255 81.6072 484.317 82.9821 486.052 84.7176C487.788 86.4531 489.163 88.5149 490.098 90.7843C491.033 93.0536 491.509 95.4855 491.5 97.9399C491.468 101.569 490.358 105.107 488.31 108.104C486.262 111.101 483.37 113.421 480 114.77L493.18 137.33H482.7L470.43 116.18ZM459.15 107.64H472.83C474.067 107.605 475.285 107.326 476.414 106.819C477.543 106.312 478.561 105.588 479.41 104.686C480.258 103.785 480.92 102.725 481.358 101.567C481.795 100.41 482 99.1769 481.96 97.9399C481.96 92.4599 477.89 88.2299 472.83 88.2299H459.15V107.64Z" fill="current"/>
      <path d="M511.63 79.27V137.33H502.09V79.27H511.63Z" fill="current"/>
      <path d="M521.66 108.3C521.66 91.47 534.35 78.19 552.02 78.19C557.037 78.1064 561.987 79.3428 566.374 81.7754C570.762 84.208 574.434 87.7513 577.02 92.05L568.72 96.86C565.65 91.13 559.35 87.48 552.05 87.48C539.52 87.48 531.23 96.36 531.23 108.3C531.23 120.24 539.52 129.12 552.05 129.12C559.35 129.12 565.74 125.47 568.72 119.75L577.02 124.48C572.21 132.94 562.75 138.41 552.02 138.41C534.35 138.41 521.66 125.14 521.66 108.3Z" fill="current"/>
      <path d="M595.7 79.27V137.33H586.16V79.27H595.7Z" fill="current"/>
      <path d="M645.31 88.4H629V137.33H619.47V88.4H603V79.27H645.31V88.4Z" fill="current"/>
      <path d="M680.06 114.28V137.33H670.52V114.28L650.28 79.28H661.15L675.33 105L689.43 79.28H700.3L680.06 114.28Z" fill="current"/>
      <path d="M787.8 137.33H778.26V95.94L760.6 125.14H759.44L741.77 95.86V137.33H732.23V79.27H742.51L760.02 108.27L777.6 79.27H787.8V137.33Z" fill="current"/>
      <path d="M834.08 125.64H809.36L805.3 137.33H794.93L816 79.27H827.44L848.44 137.33H838.14L834.08 125.64ZM830.93 116.64L821.72 90.43L812.51 116.64H830.93Z" fill="current"/>
      <path d="M896.15 98.3501C896.179 100.878 895.699 103.386 894.74 105.725C893.78 108.064 892.361 110.186 890.565 111.966C888.769 113.746 886.635 115.147 884.287 116.085C881.94 117.024 879.428 117.481 876.9 117.43H865V137.33H855.5V79.2701H876.9C879.429 79.212 881.945 79.6646 884.295 80.6008C886.646 81.5369 888.783 82.9374 890.58 84.7185C892.377 86.4996 893.797 88.6248 894.754 90.967C895.711 93.3091 896.186 95.8202 896.15 98.3501V98.3501ZM886.61 98.3501C886.61 92.4601 882.54 88.2301 876.9 88.2301H865V108.47H876.9C882.54 108.47 886.61 104.16 886.61 98.3501Z" fill="current"/>
      <path d="M901.41 124.15L909.62 119.34C911.7 125.22 916.18 129.12 924.06 129.12C931.61 129.12 934.84 125.72 934.84 121.49C934.84 116.35 930.28 114.61 921.65 111.95C912.65 109.22 903.82 105.95 903.82 94.9501C903.82 84.2501 912.69 78.1901 922.48 78.1901C926.743 78.0835 930.941 79.2437 934.544 81.524C938.147 83.8043 940.992 87.1021 942.72 91.0001L934.67 95.6401C932.6 91.0001 929 87.4801 922.48 87.4801C916.92 87.4801 913.36 90.3001 913.36 94.7001C913.36 99.1001 916.59 101.09 925.05 103.7C934.67 106.77 944.38 109.76 944.38 121.29C944.38 131.9 935.92 138.37 923.81 138.37C912.36 138.41 904.48 132.77 901.41 124.15Z" fill="current"/>
    </svg>
  );
}
