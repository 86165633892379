import { twMerge } from 'tailwind-merge';

export function EmapsIcon({ size = 22, styling }: { size?: number; styling?: string }) {
  return (
    <div className={twMerge('text-black', styling)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M419.859 438.95C430.035 438.95 438.285 430.403 438.285 419.859C438.285 409.315 430.035 400.768 419.859 400.768C409.683 400.768 401.434 409.315 401.434 419.859C401.434 430.403 409.683 438.95 419.859 438.95Z"
          fill="currentColor"
        />
        <path
          d="M256 438.95C266.176 438.95 274.425 430.403 274.425 419.859C274.425 409.315 266.176 400.768 256 400.768C245.824 400.768 237.574 409.315 237.574 419.859C237.574 430.403 245.824 438.95 256 438.95Z"
          fill="currentColor"
        />
        <path
          d="M92.1407 438.95C102.317 438.95 110.566 430.403 110.566 419.859C110.566 409.315 102.317 400.768 92.1407 400.768C81.9645 400.768 73.7151 409.315 73.7151 419.859C73.7151 430.403 81.9645 438.95 92.1407 438.95Z"
          fill="currentColor"
        />
        <path
          d="M419.859 275.091C430.035 275.091 438.285 266.544 438.285 256C438.285 245.456 430.035 236.909 419.859 236.909C409.683 236.909 401.434 245.456 401.434 256C401.434 266.544 409.683 275.091 419.859 275.091Z"
          fill="currentColor"
        />
        <path
          d="M92.1407 275.091C102.317 275.091 110.566 266.544 110.566 256C110.566 245.456 102.317 236.909 92.1407 236.909C81.9645 236.909 73.7151 245.456 73.7151 256C73.7151 266.544 81.9645 275.091 92.1407 275.091Z"
          fill="currentColor"
        />
        <path
          d="M419.859 111.232C430.035 111.232 438.285 102.685 438.285 92.1408C438.285 81.597 430.035 73.0496 419.859 73.0496C409.683 73.0496 401.434 81.597 401.434 92.1408C401.434 102.685 409.683 111.232 419.859 111.232Z"
          fill="currentColor"
        />
        <path
          d="M256 111.232C266.176 111.232 274.425 102.685 274.425 92.1408C274.425 81.597 266.176 73.0496 256 73.0496C245.824 73.0496 237.574 81.597 237.574 92.1408C237.574 102.685 245.824 111.232 256 111.232Z"
          fill="currentColor"
        />
        <path
          d="M92.1407 111.232C102.317 111.232 110.566 102.685 110.566 92.1408C110.566 81.597 102.317 73.0496 92.1407 73.0496C81.9645 73.0496 73.7151 81.597 73.7151 92.1408C73.7151 102.685 81.9645 111.232 92.1407 111.232Z"
          fill="currentColor"
        />
        <path
          d="M344.736 227.405H267.066V159.846C267.066 152.384 257.933 149.152 253.53 155.059L161.28 272C157.44 277.12 160.979 284.614 167.258 284.614H244.934V352.154C244.934 359.616 254.067 362.848 258.47 356.941L350.72 240C354.56 234.88 351.014 227.405 344.736 227.405Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
}
